var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-column" },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c("base-search", {
              staticStyle: { display: "inline-block" },
              attrs: {
                searchList: _vm.$basicSystemConfigJsglSearch,
                listQuery: _vm.listQuery,
              },
              on: { search: _vm.handleFilter, "btn-event": _vm.onBtnClicked },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "app-container flex-item" },
        [
          _c(
            "div",
            { staticClass: "bg-white fh" },
            [
              _c(
                "el-table",
                {
                  key: _vm.tableKey,
                  ref: "mainTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.list,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                    height: "calc(100% - 52px)",
                  },
                  on: {
                    "row-click": _vm.rowClick,
                    "selection-change": _vm.handleSelectionChange,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", type: "selection", width: "55" },
                  }),
                  _vm.showDescription
                    ? _c("el-table-column", {
                        attrs: { label: "Id", "min-width": "120px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.id))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          773642443
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { "min-width": "50px", label: "角色名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { "min-width": "300px", label: "用户列表" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return _vm.list.length > 0
                              ? [
                                  _c("role-users", {
                                    ref: "roleUser",
                                    attrs: {
                                      "role-id": scope.row.id,
                                      selectUsers:
                                        _vm.roleUsers.list[scope.$index],
                                    },
                                    on: {
                                      "update:selectUsers": function ($event) {
                                        return _vm.$set(
                                          _vm.roleUsers.list,
                                          scope.$index,
                                          $event
                                        )
                                      },
                                      "update:select-users": function ($event) {
                                        return _vm.$set(
                                          _vm.roleUsers.list,
                                          scope.$index,
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ]
                              : undefined
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "class-name": "status-col",
                      label: "状态",
                      width: "80",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                class: _vm._f("statusFilter")(scope.row.status),
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.statusOptions.find(
                                      (u) => u.key == scope.row.status
                                    ).display_name
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.$store.state.listLoading,
                  expression: "$store.state.listLoading",
                },
              ],
              staticClass: "dialog-mini",
              attrs: {
                width: "500px",
                title: _vm.textMap[_vm.dialogStatus],
                visible: _vm.dialogFormVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.temp,
                    "label-position": "right",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { size: "small", label: "角色名称", prop: "name" },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "50", "show-word-limit": "" },
                        model: {
                          value: _vm.temp.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "name", $$v)
                          },
                          expression: "temp.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          attrs: { placeholder: "请选择状态" },
                          model: {
                            value: _vm.temp.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "status", $$v)
                            },
                            expression: "temp.status",
                          },
                        },
                        _vm._l(_vm.statusOptions, function (item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: {
                              label: item.display_name,
                              value: item.key,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "角色行为" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            placeholder: "请选择角色行为",
                            multiple: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.temp.typeIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "typeIds", $$v)
                            },
                            expression: "temp.typeIds",
                          },
                        },
                        _vm._l(_vm.$store.state.roleType, function (item) {
                          return _c("el-option", {
                            key: item.dtCode,
                            attrs: { label: item.name, value: item.dtCode },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm.dialogStatus == "create"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.createData },
                        },
                        [_vm._v("确认")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.updateData },
                        },
                        [_vm._v("确认")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.$store.state.listLoading,
                  expression: "$store.state.listLoading",
                },
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              ref: "accessModulesDlg",
              staticClass: "dialog-mini allocation_Module",
              attrs: {
                title: _vm.accessTitle,
                visible: _vm.dialogAccessModules,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogAccessModules = $event
                },
              },
            },
            [
              _vm.dialogAccessModules
                ? _c("access-modules", {
                    ref: "accessModules",
                    attrs: { "role-id": _vm.multipleSelection[0].id },
                    on: {
                      "change-title": _vm.changeTitle,
                      close: function ($event) {
                        _vm.dialogAccessModules = false
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              staticClass: "dialog-mini",
              attrs: {
                title: "为角色分配资源",
                visible: _vm.dialogAccessResource,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogAccessResource = $event
                },
              },
            },
            [
              _vm.dialogAccessResource
                ? _c("access-resource", {
                    ref: "accessResource",
                    attrs: { "role-id": _vm.multipleSelection[0].id },
                    on: {
                      close: function ($event) {
                        _vm.dialogAccessResource = false
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              staticClass: "dialog-mini user-dialog",
              attrs: {
                title: "添加角色用户",
                visible: _vm.roleUsers.dialogUserResource,
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(_vm.roleUsers, "dialogUserResource", $event)
                },
              },
            },
            [
              _vm.roleUsers.dialogUserResource
                ? _c("selectUsersCom", {
                    ref: "selectUser",
                    attrs: {
                      hiddenFooter: true,
                      loginKey: "loginUser",
                      selectUsers:
                        _vm.roleUsers.rowIndex > -1 &&
                        _vm.roleUsers.list[_vm.roleUsers.rowIndex],
                    },
                    on: {
                      "update:selectUsers": function ($event) {
                        return _vm.$set(
                          _vm.roleUsers.rowIndex > -1 && _vm.roleUsers.list,
                          _vm.roleUsers.rowIndex,
                          $event
                        )
                      },
                      "update:select-users": function ($event) {
                        return _vm.$set(
                          _vm.roleUsers.rowIndex > -1 && _vm.roleUsers.list,
                          _vm.roleUsers.rowIndex,
                          $event
                        )
                      },
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "right" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "cancel" },
                      on: {
                        click: function ($event) {
                          _vm.roleUsers.dialogUserResource = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleSaveUsers },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }