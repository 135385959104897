var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.$store.state.listLoading,
          expression: "$store.state.listLoading",
        },
      ],
      staticClass: "compent-dialog-body",
    },
    [
      _c(
        "div",
        { staticClass: "p-m p_m_jsgl" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              class: _vm.step == "1" ? "" : "hide",
              attrs: { "label-position": "left" },
            },
            [
              _c("el-form-item", { attrs: { size: "small" } }, [
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-tree", {
                      ref: "tree",
                      attrs: {
                        data: _vm.modules,
                        "check-strictly": true,
                        "show-checkbox": "",
                        "node-key": "id",
                        "default-expand-all": "",
                        "expand-on-click-node": false,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ node }) {
                            return _c(
                              "span",
                              { staticClass: "custom-tree-node" },
                              [_c("span", [_vm._v(_vm._s(node.label))])]
                            )
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("div", { class: _vm.step == "2" ? "" : "hide" }, [
            _c(
              "div",
              { staticClass: "block" },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.roleMenuIdsAll,
                      callback: function ($$v) {
                        _vm.roleMenuIdsAll = $$v
                      },
                      expression: "roleMenuIdsAll",
                    },
                  },
                  [_vm._v("全选")]
                ),
                _vm._l(_vm.checkModules, function (item) {
                  return [
                    _c("div", { key: item.id }, [
                      _c("h4", { staticClass: "title" }, [
                        _vm._v(
                          " " +
                            _vm._s(item.parentName) +
                            " > " +
                            _vm._s(item.label) +
                            " "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "p-l-m" },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.roleMenuIds,
                                callback: function ($$v) {
                                  _vm.roleMenuIds = $$v
                                },
                                expression: "roleMenuIds",
                              },
                            },
                            _vm._l(_vm.filterMenus(item.id), function (menu) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: menu.Id,
                                  attrs: { label: menu.id, size: "small" },
                                  on: { change: _vm.onChange },
                                },
                                [_vm._v(_vm._s(menu.name))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ]),
          _c(
            "div",
            { class: _vm.step == "3" ? "" : "hide" },
            [
              _c(
                "el-select",
                {
                  staticClass: "sellect-operation",
                  attrs: {
                    id: "sereach",
                    size: "mini",
                    filterable: "",
                    clearable: "",
                  },
                  on: { change: _vm.btnmodulesreach },
                  model: {
                    value: _vm.modulesreach,
                    callback: function ($$v) {
                      _vm.modulesreach = $$v
                    },
                    expression: "modulesreach",
                  },
                },
                _vm._l(_vm.noSystemNodes, function (item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: { label: item.label, value: item.id },
                    },
                    [
                      _c("span", { staticStyle: { float: "left" } }, [
                        _vm._v(_vm._s(item.label)),
                      ]),
                    ]
                  )
                }),
                1
              ),
              _c(
                "div",
                { staticClass: "block matop" },
                [
                  _vm._l(_vm.noSystemNodes, function (node, index) {
                    return [
                      _c("div", { key: node.id }, [
                        _c(
                          "h4",
                          { staticClass: "title", attrs: { id: node.id } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(node.parentName) +
                                " > " +
                                _vm._s(node.label) +
                                " "
                            ),
                            _c(
                              "el-checkbox",
                              {
                                attrs: { indeterminate: node.isIndeterminate },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleCheckPropAllChange(
                                      $event,
                                      node.properties,
                                      index
                                    )
                                  },
                                },
                                model: {
                                  value: node.checkAll,
                                  callback: function ($$v) {
                                    _vm.$set(node, "checkAll", $$v)
                                  },
                                  expression: "node.checkAll",
                                },
                              },
                              [_vm._v("全选")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "p-l-m" },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                model: {
                                  value: node.checks,
                                  callback: function ($$v) {
                                    _vm.$set(node, "checks", $$v)
                                  },
                                  expression: "node.checks",
                                },
                              },
                              [
                                _vm._l(node.properties, function (propy) {
                                  return [
                                    _c(
                                      "el-checkbox",
                                      {
                                        key: propy.key,
                                        attrs: {
                                          label: propy.key,
                                          size: "small",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onChangeProp(index)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(propy.description))]
                                    ),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "el-dialog__footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.close } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step > 1,
                  expression: "step > 1",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.up },
            },
            [_vm._v("上一步")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "success" },
              on: { click: _vm.acceRole },
            },
            [_vm._v("下一步")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }